import React from 'react'

const style={
    minHeight: '25px',
    color: 'white',
    background: 'black',
    textAlign: 'center'
}
const Footer = (props) => {
return <div style={style}>
    <small>Designed and Built by Adam Woolf @ <a href='https://www.webspinner.eu'>WebSpinner.eu</a></small>
</div>
}

export default Footer