import React, { useState, useEffect } from "react";
import Translator from "./i18n/Translator";
import { Row, Col } from "react-bootstrap";
import portrait from "../assets/imgs/ann.jpg";
import { getAboutPageFr, getAboutPageEng, getAboutPageNl } from "../contentful";
import TitleBar from "./TitleBar";
import groupPic from "../assets/imgs/header1.jpg";
import marked from "marked";

const Biog = () => {
  const [text, setText] = useState([]);

  useEffect(() => {
    var switchedLang = window.localStorage.getItem("lang");

    if (switchedLang === "en-US") {
      getAboutPageEng().then((data) => setText(data));
    }
    if (switchedLang === "fr-FR") {
      getAboutPageFr().then((data) => setText(data));
    }
    if (switchedLang === "nl-NL") {
      getAboutPageNl().then((data) => setText(data));
    }
  }, []);

  return (
    <div className='content'>
      <TitleBar title={<Translator path='About' />} />
      <div className='biog-page'>
        <img style={{ width: "100%" }} src={groupPic} alt='' />

        {text.map((content) => {
          let biog = marked(content.fields.text1);
          let musos = marked(content.fields.musicians);
          return (
            <div>
              <section
                className='group-biog'
                dangerouslySetInnerHTML={{ __html: biog }}
              ></section>
              <section
                className='group-biog'
                dangerouslySetInnerHTML={{ __html: musos }}
              />
            </div>
          );
        })}
      </div>

      <hr />
      <Row>
        <Col md={6}>
          <img className='biog-page-portrait' src={portrait} alt='' />
        </Col>
        <Col md={6}>
          {text.map((content) => {
            let biog = marked(content.fields.text2);
            return (
              <div>
                <div dangerouslySetInnerHTML={{ __html: biog }}></div>
                <a className='btn btn-info' href='https://www.anncnop.com'>
                  www.anncnop.com
                </a>
              </div>
            );
          })}
        </Col>
      </Row>
    </div>
  );
};

export default Biog;
