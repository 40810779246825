const client = require('contentful').createClient({
    space: 'iv5w0wgz0dem',
    accessToken: 'iGkfquYQptjD7zGRlaV9N_7kU9rvH1AHFKzvB1YN950'
  })
  var gt = new Date().toISOString();

  
  const getNews = () => client.getEntries({content_type: 'news'}).then(response => response.items)
  const getNewsFr = () => client.getEntries({content_type: 'newsFr'}).then(response => response.items)
  const getNewsNl = () => client.getEntries({content_type: 'newsNl'}).then(response => response.items)
  const getAboutPageNl = () => client.getEntries({content_type: 'aboutPageNl'}).then(response => response.items)
  const getAboutPageEng = () => client.getEntries({content_type: 'aboutPageEng'}).then(response => response.items)
  const getAboutPageFr = () => client.getEntries({content_type: 'aboutPageFr'}).then(response => response.items)
  
  const getProjectFr = () => client.getEntries({content_type: 'projectFr', order: 'fields.order'}).then(response => response.items)
  const getProjectEng = () => client.getEntries({content_type: 'projectEng', order: 'fields.order'}).then(response => response.items)
  const getProjectNl = () => client.getEntries({content_type: 'projectNl', order: 'fields.order'}).then(response => response.items)
  
  
  
  
  const getVideos = () => client.getEntries({content_type: 'galleryVideo'}).then(response => response.items)
  const getBiog = (lang) => client.getEntries({content_type: 'biography', locale: lang}).then(response => response.items)
 const getGallery = () => client.getEntries({content_type: 'galleryImage'}).then(response => response.items)
 
 const getPrivacyEng = () => client.getEntries({content_type: 'privacyPolicyEng'}).then(response => response.items)
 const getPrivacyFr = () => client.getEntries({content_type: 'privacyPolicyFr'}).then(response => response.items)
 const getPrivacyNl = () => client.getEntries({content_type: 'privacyPolicy'}).then(response => response.items)

const getRecordings = () => client.getEntries({content_type: 'recording'}).then(response => response.items)



  const getSinglePostEng = (slug) => client.getEntries({'fields.label': slug, content_type: 'news'}).then(response => response.items)
  const getSinglePostFr = (slug) => client.getEntries({'fields.label': slug, content_type: 'newsFr'}).then(response => response.items)
  const getSinglePostNl = (slug) => client.getEntries({'fields.label': slug, content_type: 'newsNl'}).then(response => response.items)
  




  export {getRecordings, getVideos, getPrivacyNl, getPrivacyFr, getPrivacyEng, getProjectEng, getProjectNl, getProjectFr, getAboutPageEng, getAboutPageFr, getAboutPageNl, getNews, getNewsFr, getNewsNl, getSinglePostEng, getSinglePostFr, getSinglePostNl, getBiog,  getGallery}