import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Translator from "./i18n/Translator";
import { getPrivacyEng, getPrivacyFr, getPrivacyNl } from "../contentful";
import marked from "marked";
import { FaTimesCircle } from "react-icons/fa";

Modal.setAppElement('#root');


const Privacy = (props) => {
  const [text, setText] = useState([]);

  useEffect(() => {
    var switchedLang = window.localStorage.getItem("lang");

    if (switchedLang === "en-US") {
      getPrivacyEng().then((data) => setText(data));
    }
    if (switchedLang === "fr-FR") {
      getPrivacyFr().then((data) => setText(data));
    }
    if (switchedLang === "nl-NL") {
      getPrivacyNl().then((data) => setText(data));
    }
  }, []);

  return (
    <Modal
      isOpen={props.openModal}
      style={{ overlay: { backgroundColor: "rgba(0,0,0,.5)" } }}
      closeTimeoutMS={200}

    >
      <div>
        {text && text.map((text) => {
          const content = marked(text.fields.content);

          return (
            <div className="content">
              <FaTimesCircle
                className="close-cross"
                size={25}
                onClick={props.closeModal}
              />
              <div className="text-center">
                <div
                  className="privacy-text"
                  dangerouslySetInnerHTML={{ __html: content }}
                ></div>

                <button className="btn btn-info" onClick={props.closeModal}>
                  <Translator path="close" />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default Privacy;
