import React from 'react'
import marked from 'marked'
import '../components/calendar/calendar.css'
import Translator from './i18n/Translator'
import { Row, Col } from  'react-bootstrap'

const Event = (props) => {

    const details = marked(props.date.fields.description)

    const date = new Date(props.date.fields.date)
    return <div className={props.class}>
                {/* {props.date.fields.image ? <img style={{height:'200px'}} src={props.date.fields.image.fields.file.url} /> : null} */}
     <h5>{props.date.fields.event}</h5>
        <p>{date.toDateString()}</p>
<p>{date.getHours()}:{String(date.getMinutes()).padStart(2, '0')}</p>
        <p>{props.date.fields.venue}</p>
        {details ? 
        <section style={{textAlign:'center', fontStyle:'italic'}} dangerouslySetInnerHTML={{ __html: details}}/> : null}
     
   
   
       
        <a style={{marginTop:'10px'}} className="xsmall-button" href={props.date.fields.link} target="_blank" >
            <Translator path="ViewConcert"/>
        </a>
    </div>
}

export default Event
