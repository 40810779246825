import { useTranslation } from 'react-i18next'
// importing the useTranslation hook

const Translator = ({ path }) => {
  const { t } = useTranslation() // t is the translation function

  // path is going to be the path to our translations (e.g. "home.message")
  return t(path)
}

export default Translator