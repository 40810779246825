import React from "react";
import { Link } from "react-router-dom";
import { FaTwitter, FaFacebook, FaSpotify, FaYoutube } from "react-icons/fa";
import { Dropdown, NavLink, NavItem } from "react-bootstrap";
import Translator from "../i18n/Translator";
import I18n from "../i18n/i18n";
import logo from "../../assets/logo.jpg";
import MechelenLogo from "../../assets/imgs/Mechelen_logo.svg";

import "./SideDrawer.css";

const sideDrawer = (props) => {
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }
  return (
    <div>
      <nav className={drawerClasses}>
        <div className='header'>
          <Link onClick={props.click} to='/'>
            <img style={{ width: "100%" }} src={logo} alt='' />
          </Link>

          <hr></hr>
        </div>
        <ul>
          <li>
            <Link onClick={props.click} to='/biography'>
              <Translator path='About' />
            </Link>
          </li>
          <li>
            <Link onClick={props.click} to='/posts'>
              <Translator path='News' />
            </Link>
          </li>
          <li>
            <Link onClick={props.click} to='/projects'>
              <Translator path='Projects' />
            </Link>
          </li>

          <li>
            <Link onClick={props.click} to='/recordings'>
              <Translator path='Recordings' />
            </Link>
          </li>

          <li>
            <Link onClick={props.click} to='/media'>
              <Translator path='Media' />
            </Link>
          </li>

          <li>
            <Link onClick={props.click} to='/contact'>
              <Translator path='Contact' />
            </Link>
          </li>
        </ul>
        <div className='social'>
          <a
            href='https://www.facebook.com/lepavillondemusique/'
            target='_blank'
          >
            <FaFacebook size={35} />
          </a>
        </div>
        {/*
            <a href="https://twitter.com/TabeaDebus" target="_blank"><FaTwitter size={35}/></a>
      
            <a href="https://www.youtube.com/channel/UCIwHWkZF447ssRHDaTpWJpg" target="_blank"><FaYoutube size={35}/></a>
      
            <a href="https://open.spotify.com/artist/1MbLfjWZWdE2dt3yOJnU4e?si=Pz6mmUhSTG2Nwwu8G5Xlrg" target="_blank"><FaSpotify size={35}/></a>
        </div> */}
        <div className='i18n'>
          <I18n />
        </div>
        <div className='mechelen-logo'>
          <img src={MechelenLogo} />
        </div>
      </nav>
    </div>
  );
};

export default sideDrawer;
