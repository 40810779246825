import React, { Component } from "react";
import { getSinglePostEng, getSinglePostFr, getSinglePostNl } from "../contentful";
import SingleNewsDisplay from './SingleNewsDisplay'

class SinglePost extends Component {
  state = {
  post: []
  };



  componentDidMount() {


    var switchedLang = window.localStorage.getItem('lang')

        if(switchedLang === 'en-US'){ getSinglePostEng(this.props.match.params.id).then(data => this.setState({post: data}))}
        if(switchedLang === 'fr-FR'){ getSinglePostFr(this.props.match.params.id).then(data => this.setState({post: data}))}
        if(switchedLang === 'nl-NL'){ getSinglePostNl(this.props.match.params.id).then(data => this.setState({post: data}))}


    // getSinglePost(this.props.match.params.id,).then((data) => 
    //   this.setState({
    //    post: data
    //   })
    // );

  }

  render() {
    return (
      <div>
     {this.state.post.map(data => <SingleNewsDisplay news={data} />  )}
     </div>
    );
  }
}

export default SinglePost;
