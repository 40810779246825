import React, { Component } from "react";
import Main from "./components/Main";
import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
import Posts from "./components/Posts";
import Footer from "./components/Footer";
import Discography from "./components/Discography";
import Toolbar from "./components/Toolbar/Toolbar";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import Backdrop from "./components/Backdrop/Backdrop";
import SinglePost from "./components/SinglePost";
import Calendar from "./components/calendar/Calendar";
import Biog from "./components/Biog";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Projects from "./components/Projects";

class App extends Component {
  state = {
    sideDrawerOpen: false,
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  componentDidMount() {
    var switchedLang = window.localStorage.getItem("lang");
    if (!switchedLang) {
      window.localStorage.setItem("lang", "nl-NL");
    }
  }

  render() {
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }
    return (
      <Router>
        <div style={{ height: "100%" }}>
          <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer
            show={this.state.sideDrawerOpen}
            click={this.drawerToggleClickHandler}
          />
          {backdrop}
          <main style={{ marginTop: "56px" }}></main>{" "}
          <Switch>
            <Route exact path='/' component={Main} />
            <Route path='/media' component={Gallery} />
            <Route path='/calendar' component={Calendar} />
            <Route path='/contact' component={Contact} />
            <Route path='/posts/:id' component={SinglePost} />
            <Route path='/posts' component={Posts} />
            <Route path='/recordings' component={Discography} />
            <Route path='/biography' component={Biog} />
            <Route path='/projects' component={Projects} />
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
