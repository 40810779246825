import React, { useEffect, useState } from "react";
import {
  FaTwitter,
  FaFacebook,
  FaSpotify,
  FaYoutube,
  FaEnvelopeOpen,
  FaPhoneAlt,
  FaGlobe,
} from "react-icons/fa";
import Translator from "./i18n/Translator";
import TitleBar from "./TitleBar";
import group from "../assets/imgs/line-up.jpg";
import Privacy from "./Privacy";
import logo from "../assets/logo.jpg";
import agentLogo from "../assets/imgs/ClaraMusica.jpg";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className='content'>
        <TitleBar title={<Translator path='Contact' />} />
        <div className='contact-middle text-center'>
          <form
            className='form'
            action='https://formspree.io/f/meqpkyzr'
            method='POST'
          >
            <div>
              <img style={{ height: "100px" }} src={logo} alt='' />
            </div>
            <a href='mailto:info@lepavillondemusique.be'>
              <div style={{ marginBottom: "15px" }}>
                <FaEnvelopeOpen size={44} />
                <br></br>
              </div>

              <Translator path='ContactText' />
              <p>info@lepavillondemusique.be</p>
            </a>
            <div style={{ paddingBottom: "15px" }}>
              <FaPhoneAlt /> +32 477 53 97 48
            </div>
            <hr />
            <div className='agent-container'>
              <div>
                <img style={{ height: "150px" }} src={agentLogo} alt='' />
              </div>
              <p>
                <b>Booking Agent</b>
              </p>
              <p>CLARAMUSICA ARTIST MANAGEMENT </p>
              <p>Jean-Marc Poncelet - Directeur</p>
              <div style={{ paddingBottom: "15px" }}>
                <div>
                  <FaPhoneAlt /> +32 478 34 82 85
                </div>
                <div>
                  <FaGlobe />{" "}
                  <a target='_blank' href='https://www.claramusica.com'>
                    www.claramusica.com
                  </a>
                </div>
              </div>
            </div>
            <hr />
            <p>
              <b>
                <Translator path='FormText' />{" "}
              </b>{" "}
            </p>

            <label>
              <Translator path='FormName' />
              <input
                className='form-control'
                type='text'
                required
                name='name'
              />
              <Translator path='FormEmail' />
              <input
                className='form-control'
                type='email'
                name='_replyto'
                required
              />
            </label>
            <label>
              <span>
                <Translator path='PrivacyAgreed1' />
              </span>
              <span className='privacy-link' onClick={() => setModalOpen(true)}>
                <Translator path='PrivacyAgreed2' />
              </span>
              <input
                className='privacy-button'
                type='checkbox'
                name='privacy agreed'
                required
              />
            </label>

            <button
              style={{ width: "50%", alignSelf: "center" }}
              className='btn btn-info'
              type='submit'
            >
              <Translator path='FormButton' />
            </button>
          </form>

          <Privacy
            openModal={modalOpen}
            closeModal={() => setModalOpen(false)}
          />

          <a
            href='https://www.facebook.com/lepavillondemusique/'
            target='_blank'
          >
            <FaFacebook size={44} />
          </a>
        </div>
        <div className='contact-middle-photo'>
          <img src={group} alt='' />
        </div>
        {/* <div className="social">
            <a href="https://www.facebook.com/tabea.debus" target="_blank"><FaFacebook size={35}/></a>
        
            <a href="https://twitter.com/TabeaDebus" target="_blank"><FaTwitter size={35}/></a>
      
            <a href="https://www.youtube.com/channel/UCIwHWkZF447ssRHDaTpWJpg" target="_blank"><FaYoutube size={35}/></a>
      
            <a href="https://open.spotify.com/artist/1MbLfjWZWdE2dt3yOJnU4e?si=Pz6mmUhSTG2Nwwu8G5Xlrg" target="_blank"><FaSpotify size={35}/></a>
        </div> */}
      </div>
    </>
  );
};

export default Contact;
