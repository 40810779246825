import React from "react";
import marked from "marked";
import { Link } from "react-router-dom";
import Translator from "./i18n/Translator";
import { FaTwitter, FaFacebook } from "react-icons/fa";

import { Row, Col } from "react-bootstrap";

const BlogItem = ({ article }) => {
  console.log(article);
  const {
    title,
    mainImage,
    content,
    label,
    videoMp4,
    youTube,
  } = article.fields;
  const postBody = marked(content);
  const excerpt = postBody.split(" ").splice(0, 20).join(" ") + "...";
  let youTubeVid;
  if (youTube) youTubeVid = marked(youTube);
  return (
    <div className='news-item'>
      <Row>
        {mainImage && (
          <Col md={6}>
            {" "}
            <img className='blog-item-image' src={mainImage.fields.file.url} />
          </Col>
        )}

        <Col>
          {" "}
          <h5>{title}</h5>
          {youTubeVid && (
            <section dangerouslySetInnerHTML={{ __html: youTubeVid }} />
          )}
          {content && (
            <section dangerouslySetInnerHTML={{ __html: postBody }} />
          )}
          {videoMp4 && (
            <video className='video' controls>
              <source src={videoMp4.fields.file.url} type='video/mp4' />
              Your browser does not support the video tag
            </video>
          )}
        </Col>
      </Row>

      <hr />
    </div>
  );
};

export default BlogItem;
