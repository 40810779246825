import React, { Component } from "react";
import "../styles/stylesheet.css";
import Hero from "./hero/Hero";
import { Row, Col } from "react-bootstrap";
import {
  getNews,
  getNewsNl,
  getNewsFr,
  getAboutPageEng,
  getAboutPageFr,
  getAboutPageNl,
} from "../contentful";
import Event from "./Event";
import { Link } from "react-router-dom";
import marked from "marked";
import Video from "./videos/video";
import groupRow from "../assets/imgs/line-up.jpg";

import Translator from "./i18n/Translator";
import { FaArrowAltCircleRight } from "react-icons/fa";

class Main extends Component {
  state = {
    aboutText: [],
    news: [],
  };

  getMarkdownText() {
    var rawMarkup = marked(this.state.video[0].fields.youTube, {
      sanitize: true,
    });
    return { __html: rawMarkup };
  }

  componentDidMount() {
    let switchedLang = window.localStorage.getItem("lang");
    if (switchedLang === null || switchedLang === undefined) {
      getNewsNl().then((data) => this.setState({ news: data }));
      getAboutPageNl().then((data) => this.setState({ aboutText: data }));
    }

    if (switchedLang === "en-US") {
      getNews().then((data) => this.setState({ news: data }));
      getAboutPageEng().then((data) => this.setState({ aboutText: data }));
    }
    if (switchedLang === "fr-FR") {
      getNewsFr().then((data) => this.setState({ news: data }));
      getAboutPageFr().then((data) => this.setState({ aboutText: data }));
    }
    if (switchedLang === "nl-NL") {
      getNewsNl().then((data) => this.setState({ news: data }));
      getAboutPageNl().then((data) => this.setState({ aboutText: data }));
    }
  }

  render() {
    return (
      <div>
        <Hero />
        <div className='middle'>
          <h3>le Pavillon de Musique</h3>
          {this.state.aboutText.map((content) => {
            const text = marked(content.fields.text1);
            return (
              <div
                className='group-biog-2-columns'
                dangerouslySetInnerHTML={{ __html: text }}
              ></div>
            );
          })}
          <hr />
          <h3>
            <Translator path='News' />
          </h3>
          {this.state.news.slice(0, 1).map((content) => {
            const text = marked(content.fields.content);
            let youTube;
            if (content.fields.youTube)
              youTube = marked(content.fields.youTube);
            return (
              <div>
                <h5 style={{ textAlign: "center" }}>{content.fields.title}</h5>

                {content.fields.mainImage && content.fields.youTube && (
                  <>
                    <Row>
                      <Col>
                        <img
                          style={{ width: "100%" }}
                          src={content.fields.mainImage.fields.file.url}
                        />
                      </Col>
                      <Col>
                        <div dangerouslySetInnerHTML={{ __html: youTube }} />
                        {content.fields.content && (
                          <div dangerouslySetInnerHTML={{ __html: text }}></div>
                        )}
                      </Col>
                    </Row>
                    {content.fields.videoMp4 && (
                      <video className='video' controls>
                        <source
                          src={content.fields.videoMp4.fields.file.url}
                          type='video/mp4'
                        />
                        Your browser does not support the video tag
                      </video>
                    )}
                  </>
                )}

                {content.fields.mainImage && !content.fields.youTube && (
                  <div className='text-center'>
                    <img
                      style={{ width: "60%" }}
                      src={content.fields.mainImage.fields.file.url}
                    />
                    {content.fields.content && (
                      <div dangerouslySetInnerHTML={{ __html: text }}></div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {content.fields.videoMp4 && (
                        <video className='video' controls>
                          <source
                            src={content.fields.videoMp4.fields.file.url}
                            type='video/mp4'
                          />
                          Your browser does not support the video tag
                        </video>
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          <Link
            style={{ marginTop: "5px" }}
            className='btn btn-info'
            to='/posts'
          >
            <Translator path='News' /> <FaArrowAltCircleRight />
          </Link>
        </div>
        <div>
          <img style={{ width: "100%" }} src={groupRow} alt='' />
        </div>
      </div>
    );
  }
}

export default Main;
