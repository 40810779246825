import React from "react";
import { FaTwitter, FaYoutube, FaSpotify, FaFacebook } from "react-icons/fa";
import I18n from "../i18n/i18n";
import logo from "../../assets/logo.jpg";
import Mechelenlogo from "../../assets/imgs/Mechelen_logo.svg";
import { Link } from "react-router-dom";

import "./Toolbar.css";
import DrawerToggleButton from "../DrawerToggleButton/DrawerToggleButton";

const toolbar = (props) => (
  <header className='toolbar'>
    <nav className='toolbar__navigation'>
      <div className='toolbar__toggle-button'>
        <DrawerToggleButton click={props.drawerClickHandler} />
      </div>
      <div className='toolbar__logo'>
        <Link to='/'>
          <img src={logo} alt='' />
        </Link>
      </div>
      <img className='toolbar__logo__mechelen' src={Mechelenlogo} />
      <div className='spacer' />
      <div className='toolbar_navigation-items'>
        <div>
          <I18n />
        </div>

        {/*<li>
            <a href="https://twitter.com/TabeaDebus" target="_blank"><FaTwitter/></a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCIwHWkZF447ssRHDaTpWJpg" target="_blank"><FaYoutube/></a>
          </li>
          <li>
            <a href="https://open.spotify.com/artist/1MbLfjWZWdE2dt3yOJnU4e?si=Pz6mmUhSTG2Nwwu8G5Xlrg" target="_blank"><FaSpotify/></a>
          </li> */}
      </div>
    </nav>
  </header>
);

export default toolbar;
