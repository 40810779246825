import React, { useState, useEffect } from "react";
import Translator from './i18n/Translator'
import TitleBar from './TitleBar'
import ImageGrid from './imageGrid/ImageGrid'
import Modal from './imageGrid/Modal'
import { Row, Col } from 'react-bootstrap'
import { getVideos } from '../contentful';
import marked from 'marked';
 
import ann from '../assets/imgs/AnnCnop.jpg';
import ensemble from '../assets/imgs/lePavillondeMusique.jpg'


const Gallery = () => {

const [selectedImg, setSelectedImg] = useState(null)
const [title, setTitle] = useState(null)
const [videos, setVideos] = useState([])

useEffect(() => {
    getVideos().then(data => setVideos(data))
}, [])


  
    return (
        <div className="content">
            <Row>
                <Col md={8}>
                <TitleBar title={<Translator path="Gallery"/>} />
        <ImageGrid setSelectedImg={setSelectedImg} title={title} setTitle={setTitle}/>
       {selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} title={title} />}


                </Col>
                <Col md={4}>
                    <h3>Video</h3>
                    {videos.map(video => {
                        let film = marked(video.fields.youTubeCode)
                        return <div>
                            <p>{video.fields.title}</p>
                            <section dangerouslySetInnerHTML={{__html: film}} />

                        </div>

                    })}
                </Col>
            </Row>
       
<hr/>
<div>
    <h3>Downloads</h3>
<Row>
    <Col md={6}>
        <div>
        <img style={{width:'100%', marginBottom:'5px'}} src={ensemble} alt=""/>
        <a className="btn btn-info" href={ensemble} download  >Download</a>
        </div>

    </Col>
    <Col md={6}>
        <img style={{width:'100%', marginBottom:'5px'}} src={ann} alt=""/>
        <a className="btn btn-info" href={ensemble} download  >Download</a>

    </Col>
</Row>
</div>
</div>
    );
  
}

export default Gallery;
