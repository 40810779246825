import React, { useState, useEffect } from "react";
import { getProjectFr, getProjectNl, getProjectEng } from "../contentful";
import marked from "marked";
import { Row, Col } from "react-bootstrap";
import TitleBar from "./TitleBar";
import Translator from "./i18n/Translator";
import { Link } from "react-router-dom";

const Projects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    var switchedLang = window.localStorage.getItem("lang");

    if (switchedLang === "en-US") {
      getProjectEng().then((data) => setProjects(data));
    }
    if (switchedLang === "fr-FR") {
      getProjectFr().then((data) => setProjects(data));
    }
    if (switchedLang === "nl-NL") {
      getProjectNl().then((data) => setProjects(data));
    }
  }, []);

  return (
    <div className='content'>
      <TitleBar title={<Translator path='Projects' />} />
      {projects.map((project) => {
        const content = marked(project.fields.content);

        return (
          <div className='news-item'>
            <Row>
              <Col md={6}>
                {" "}
                <h4>{project.fields.title}</h4>
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
              </Col>
              <Col md={6}>
                {" "}
                <img
                  style={{ width: "100%" }}
                  src={project.fields.mainImage.fields.file.url}
                  alt=''
                />
              </Col>
            </Row>
            <hr />
            <Link to='/contact' className='btn btn-info'>
              Contact le Pavillon de Musique
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Projects;
