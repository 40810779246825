import React from 'react'
import Translator from '../i18n/Translator'
import { Link } from 'react-router-dom'
import './hero.css'
import Slider from '../Slider'

const Hero = (props) => {
return <div>
<Slider />
   </div>
}

export default Hero