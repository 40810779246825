import React from "react";
// JSX
import HeroSlider, { Slide, Nav, OverlayContainer } from "hero-slider";
import Translator from './i18n/Translator'
import { Link } from 'react-router-dom'

// Images
import image1 from '../assets/imgs/header1.jpg'
import image2 from '../assets/imgs/header2.jpg'
import image3 from '../assets/imgs/header3.jpg'


const slider = () => {
  return (
    <HeroSlider
      slidingAnimation="fade"
      orientation="horizontal"
      initialSlide={1}
      onBeforeChange={(previousSlide, nextSlide) =>
        console.log("onBeforeChange", previousSlide, nextSlide)
      }
      onChange={nextSlide => console.log("onChange", nextSlide)}
      onAfterChange={nextSlide => console.log("onAfterChange", nextSlide)}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.33)"
      }}
      settings={{
        slidingDuration: 450,
        slidingDelay: 0,
        shouldAutoplay: true,
        shouldDisplayButtons: true,
        autoplayDuration: 5000,
        height: "100vh"
      }}
    >
      <OverlayContainer>
          <div className="hero" style={{height:'100%'}}>
   
    </div>
      </OverlayContainer>

      

      <Slide
        background={{
          backgroundImage: image1,
          // backgroundAttachment: "fixed",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',

        }}
      />

      <Slide
        background={{
          backgroundImage: image2,
          // backgroundAttachment: "fixed",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      />

      <Slide
        background={{
          backgroundImage: image3,
          // backgroundAttachment: "fixed",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      />



      <Nav />
    </HeroSlider>
  );
};

export default slider;
