import React, { useState, useEffect } from 'react'
import { getRecordings } from '../contentful'
import Translator from '../components/i18n/Translator'
import Recording from './Recording'
import TitleBar from './TitleBar'
import {Row, Col} from 'react-bootstrap'
import marked from 'marked'

const Discography  = () => {
    const [cds, setCds] = useState([])

    useEffect(() => {
        getRecordings().then(data => setCds(data))

    }, [])
  

        return (
            <div className="content">
                    <TitleBar title={<Translator path="Recordings"/>} />

        {cds.map(cd => {
            let switchedLang = window.localStorage.getItem('lang')
            let text;
            if(!switchedLang){text = marked(cd.fields.textNl) }
            if(switchedLang === 'en-US'){text = marked(cd.fields.textEn) }
            if(switchedLang === 'fr-FR'){text = marked(cd.fields.textFr) }
            if(switchedLang === 'nl-NL'){text = marked(cd.fields.textNl) }

            return (
                <div>
                    <Row>
                        <Col>
                            <h3>{cd.fields.title}</h3>
                            <div className="text" dangerouslySetInnerHTML={{__html:text}}/>

                        </Col>
                        <Col>
                        <img src={cd.fields.cover.fields.file.url} style={{width:'100%'}}/>
                        <div style={{marginTop:20}}>
                        {cd.fields.mp3 && cd.fields.mp3.map(mp3 =>
                        <div>
                            <small>{mp3.fields.title}</small>
                                <audio controls>
                                    <source src={mp3.fields.file.url}/>
                                </audio>
                                </div>
                                )}
                                </div>
                        </Col>
                    </Row>

                </div>
            )






        })}
            
            </div>
        )
    }


export default Discography