import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaFacebook} from'react-icons/fa'
// useTranslation is a hook 
// that returns a translation function (t) and the i18n instance



import Flag from './Flag'

const I18n = () => {
  const { i18n } = useTranslation()
  // i18n instance

  function handleChangeLanguage(language) {
    // changing the language when this function is called
    i18n.changeLanguage(language)
    window.localStorage.setItem('lang', language)

    window.location.reload()
  }

  const selectedLanguage = i18n.language
  return (
    <div style={{display:'flex', color:'whitesmoke'}} >
      <h6 className="flag" onClick={() => handleChangeLanguage('nl-NL')} // changes the language to pt-BR
>NL</h6>
      <h6 className="flag" style={{margin:'0 10px'}} onClick={() => handleChangeLanguage('fr-FR')} // changes the language to en-US
>FR</h6>
      <h6 className="flag" onClick={() => handleChangeLanguage('en-US')} // changes the language to en-US
>EN</h6>


   
    </div>
  )
}

export default I18n